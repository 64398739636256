@mixin paddings {

  @for $i from 0 through 10 {
    [p-r-#{$i*8}] {
      padding-right: #{$i*8}px !important
    }
  }

  @for $i from 0 through 10 {
    [p-b-#{$i*8}] {
      padding-bottom: #{$i*8}px !important
    }
  }

  @for $i from 0 through 10 {
    [p-l-#{$i*8}] {
      padding-left: #{$i*8}px !important
    }
  }

  @for $i from 0 through 10 {
    [p-t-#{$i*8}] {
      padding-top: #{$i*8}px !important
    }
  }
}

@mixin margins {

  @for $i from 0 through 10 {
    [m-r-#{$i*8}] {
      margin-right: #{$i*8}px !important
    }
  }

  @for $i from 0 through 10 {
    [m-b-#{$i*8}] {
      margin-bottom: #{$i*8}px !important
    }
  }

  @for $i from 0 through 10 {
    [m-l-#{$i*8}] {
      margin-left: #{$i*8}px !important
    }
  }

  @for $i from 0 through 10 {
    [m-t-#{$i*8}] {
      margin-top: #{$i*8}px !important
    }
  }
}