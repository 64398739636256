/* You can add global styles to this file, and also import other style files */
@import 'assets/themes/main/theme.scss';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */

  // this counteracts the rubber band effect
  &.is-mobile {
    position: fixed;
    overflow: hidden;
    width: 100%;
  }
}

* {
  box-sizing: border-box;
}